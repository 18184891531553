import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BitcoinIcon } from 'assets/icons/payment-options-icon/bitcoin-icon.svg';
import { BTC_PAYMENT_TITLE } from 'pages/PagePurchase/components/ui/PaymentOption/constants/payment-option.const';
import { EPaymentType } from 'pages/PagePurchase/types/payment-type.enum';
import { selectBtcBalanceFetchStatus } from 'store/payment-reducer/payment.selectors';

import * as S from './BtcPaymentCard.style';

interface PaymentOptionBtcProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
  balance: number;
  amount: number;
}

export const BtcPaymentCard: React.FC<PaymentOptionBtcProps> = (props) => {
  const { handlePaymentButtonClick, balance, amount } = props;
  const paymentStatus = useSelector(selectBtcBalanceFetchStatus);
  return (
    <S.CardWrapper>
      <S.CardTitleWrapper>
        <BitcoinIcon />
        <S.CartTitle>{BTC_PAYMENT_TITLE}</S.CartTitle>
      </S.CardTitleWrapper>
      <S.AmountInfo>
        <S.AmountContainer>
          <S.AmountTitle>on your balance</S.AmountTitle>
          <S.Amount>{balance} BTC</S.Amount>
        </S.AmountContainer>
        <S.AmountContainer>
          <S.AmountTitle>you pay</S.AmountTitle>
          <S.Amount>{amount} BTC</S.Amount>
        </S.AmountContainer>
      </S.AmountInfo>
      {paymentStatus === 'pending' ? (
        <S.StyledSpinner />
      ) : (
        <S.PayButton
          text={BTC_PAYMENT_TITLE}
          onClick={() => handlePaymentButtonClick(EPaymentType.BTC)}
        />
      )}
    </S.CardWrapper>
  );
};
