import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BnbIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/bnb.svg';
import { ReactComponent as EthIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/eth.svg';
import { ReactComponent as UsdcIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/usdc.svg';
import { ReactComponent as UsdtIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/usdt.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/payment-options-icon/wallet.svg';
import {
  CRYPTO_PAYMENT_INFO_TEXT,
  CRYPTO_PAYMENT_TITLE,
} from 'pages/PagePurchase/components/ui/PaymentOption/constants/payment-option.const';
import { EPaymentType } from 'pages/PagePurchase/types/payment-type.enum';
import { selectAlphaPoFetchStatus } from 'store/payment-reducer/payment.selectors';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';

import * as S from 'pages/PagePurchase/components/ui/PaymentOption/PaymentOption.styled';

interface CryptoPaymentProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
}

export const CryptoPaymentCard: React.FC<CryptoPaymentProps> = ({ handlePaymentButtonClick }) => {
  const paymentStatus = useSelector(selectAlphaPoFetchStatus);
  return (
    <S.CardWrapper>
      <S.CardTitleWrapper>
        <WalletIcon />
        <S.CartTitle>{CRYPTO_PAYMENT_TITLE}</S.CartTitle>
      </S.CardTitleWrapper>
      <S.CardText>{CRYPTO_PAYMENT_INFO_TEXT}</S.CardText>
      <S.IconsContainer $iconGap={8}>
        <UsdtIcon />
        <UsdcIcon />
        <EthIcon />
        <BnbIcon />
        <S.IconText>and many more</S.IconText>
      </S.IconsContainer>
      {paymentStatus === 'pending' ? (
        <S.StyledSpinner />
      ) : (
        <S.PayButton
          text={CRYPTO_PAYMENT_TITLE}
          onClick={() => handlePaymentButtonClick(EPaymentType.CRYPTO)}
        />
      )}
    </S.CardWrapper>
  );
};
