import { HOUR_IN_SECONDS } from 'constants/time/hour-in-seconds.const';
import dayjs from 'dayjs';

export function isTimePassed(time: string | null): boolean {
  if (time) {
    const nowUnix = dayjs.utc().format();
    const timeDifferenceInSeconds = dayjs(nowUnix).diff(time, 'seconds');
    return timeDifferenceInSeconds > HOUR_IN_SECONDS * 24 * 14;
  }
  return true;
}
