import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CardIcon } from 'assets/icons/payment-options-icon/card.svg';
import { ReactComponent as ApplePayIcon } from 'assets/icons/payment-options-icon/card-pay-icons/apple-pay.svg';
import { ReactComponent as GooglePayIcon } from 'assets/icons/payment-options-icon/card-pay-icons/google-pay.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/payment-options-icon/card-pay-icons/master-card.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/payment-options-icon/card-pay-icons/visa.svg';
import {
  CARD_PAYMENT_INFO_TEXT,
  CARD_PAYMENT_TITLE,
} from 'pages/PagePurchase/components/ui/PaymentOption/constants/payment-option.const';
import { EPaymentType } from 'pages/PagePurchase/types/payment-type.enum';
import { selectMercuryoFetchStatus } from 'store/payment-reducer/payment.selectors';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';

import * as S from 'pages/PagePurchase/components/ui/PaymentOption/PaymentOption.styled';

interface CardPaymentProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
}

export const CardPaymentCard: React.FC<CardPaymentProps> = ({ handlePaymentButtonClick }) => {
  const paymentStatus = useSelector(selectMercuryoFetchStatus);
  return (
    <S.CardWrapper>
      <S.CardTitleWrapper>
        {<CardIcon />}
        <S.CartTitle>{CARD_PAYMENT_TITLE}</S.CartTitle>
      </S.CardTitleWrapper>
      <S.CardText>{CARD_PAYMENT_INFO_TEXT}</S.CardText>
      <S.IconsContainer $iconGap={12}>
        <VisaIcon />
        <MasterCardIcon />
        <ApplePayIcon />
        <GooglePayIcon />
      </S.IconsContainer>
      {paymentStatus === 'pending' ? (
        <S.StyledSpinner />
      ) : (
        <S.PayButton
          text={CARD_PAYMENT_TITLE}
          onClick={() => handlePaymentButtonClick(EPaymentType.CARD)}
        />
      )}
    </S.CardWrapper>
  );
};
